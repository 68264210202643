<template>
    <div @mouseleave="timeoutToggleView" @mouseenter="resetTimeout">
        <div
            class="flex relative justify-center items-center h-12 w-12 ml-2 rounded-full bg-green-400
            text-white shadow-md transition hover:bg-green-500 cursor-pointer"
            @click="toggleView"
        >
            <div class="flex flex-col">
                <div class="flex mb-1">
                    <span class="week-square flex mr-1" :class="smallSquareActiveClass(0)" />
                    <span class="week-square flex mr-1" :class="smallSquareActiveClass(1)" />
                    <span class="week-square flex mr-1" :class="smallSquareActiveClass(2)" />
                    <span class="week-square flex" :class="smallSquareActiveClass(3)" />
                </div>
                <div class="flex">
                    <span class="week-square flex mr-1" :class="smallSquareActiveClass(4)" />
                    <span class="week-square flex mr-1" :class="smallSquareActiveClass(5)" />
                    <span class="week-square flex" :class="smallSquareActiveClass(6)" />
                </div>
            </div>
        </div>
        <div v-if="visible" class="floating-container absolute py-4 px-7 rounded-md shadow-md bg-green-400 text-white">
            <p class="text-center mb-2 pb-2 border-b border-white">
                {{ $t('calendar.hide_show_week_days') }}
            </p>

            <div v-for="(day, index) in weekDays" :key="day" class="flex w-full justify-between items-center mb-2">
                <p>
                    {{ $t(`calendar.${day}`) }}
                </p>
                <div class="cursor-pointer" @click="changeState(index)">
                    <fa-icon v-if="!weekDaysVisible.includes(index)" :icon="['fas', 'eye']" fixed-width class="ml-3 cursor-pointer" />
                    <fa-icon v-else :icon="['fas', 'eye-slash']" fixed-width class="ml-3 cursor-pointer" />
                </div>
            </div>

            <div class="mt-3 pt-2 border-t border-white text-center">
                <el-button plain round class="w-full focus:outline-none" @click="updateWeekDaysVisibility">
                    {{ $t('calendar.save') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isSettingsShowHideVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            visible:               false,
            timeout:               null,
            weekDaysVisible:       [],
            weekDaysVisiblePrimal: [],
            weekDays:              ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        };
    },

    computed: {
        isAnotherVisible() {
            return this.isSettingsShowHideVisible;
        },
    },

    watch: {
        isSettingsShowHideVisible() {
            if (this.isAnotherVisible) this.visible = false;

            clearTimeout(this.timeout);
        },
    },

    created() {
        const days = window.Cookies.get('week_days_visibility') ? JSON.parse(window.Cookies.get('week_days_visibility')) : [];
        this.weekDaysVisible = days;
        this.weekDaysVisiblePrimal = days;
    },

    methods: {
        toggleView() {
            this.visible = !this.visible;
            this.weekDaysVisible = JSON.parse(JSON.stringify(this.weekDaysVisiblePrimal));
            this.$emit('visibilityChange', this.visible);
        },

        changeState(index) {
            const place = this.weekDaysVisible.indexOf(index);

            // If only one day left to hide, prevent from this
            if (place === -1 && this.weekDaysVisible.length === 6) {
                return;
            }
            // @Filip
            place === -1 ? this.weekDaysVisible.push(index) : this.weekDaysVisible.splice(place, 1);
        },

        smallSquareActiveClass(index) {
            return this.weekDaysVisible.includes(index) ? 'bg-gray-600' : 'bg-white';
        },

        updateWeekDaysVisibility() {
            this.weekDaysVisiblePrimal = this.weekDaysVisible;
            // @Filip
            const daysUpdated = this.weekDaysVisible.map(item => (item < 6 ? item + 1 : 0));
            window.Cookies.set('week_days_visibility', daysUpdated, { expires: 365 });
            this.$emit('change', daysUpdated);
            this.toggleView();
        },

        resetTimeout() {
            clearTimeout(this.timeout);
        },

        timeoutToggleView() {
            if (!this.visible) return;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.toggleView, 3000);
        },
    },
};
</script>

<style scoped>
    .week-square {
        content: '';
        width: 6px;
        height: 6px;
    }

    .floating-container {
        bottom: 55px;
        left: 40px;
    }
</style>
